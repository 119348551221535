/* eslint-disable no-param-reassign */
import i18next from "i18next";
import languageResources from "../language";

// hh-menu에서 사용되는 menu.json convert
function convertMenuJson(menuJson = []) {
   const newMenuJson = [...menuJson];
   menuJson.forEach((property) => {
      if(property.id) {
         property.name = i18next.t(`menu.${property.id}`);
      }

      if(property.childNode) {
         const childNodeQueue = [...property.childNode];
         while(childNodeQueue.length > 0) {
            const childNode = [...childNodeQueue];
            childNode.forEach((childProperty) => {
               if(childProperty.id) {
                  let key = `menu.${childProperty.id}`;
                  if(childProperty.id === "filmboxConfig") key = `menu.filmbox`; // filmbox open menu와 id겹침
                  childProperty.name = i18next.t(key);
               }

               childNodeQueue.shift();
               if(childProperty.childNode) childNodeQueue.push(...childProperty.childNode);
            });
         }
      }
   });

   return newMenuJson;
}

function initI18N() {
   // TODO :: 기본 lng 설정, localStorage lng 설정
   // const { language } = navigator;
   const lng = getlanguage();

   i18next.init({
      lng,
      debug: false,
      resources: languageResources
   }, (err, t) => {
      if (err) {
         console.error(err);
      }
   });

   window.i18next = i18next;
}

function getlanguage() {
   let locale = "en";

   const storageLocale = localStorage.getItem("i18nextLng");
   if(storageLocale) return storageLocale;

   try{
      const language = navigator.language.toLocaleLowerCase();
      if (!language.indexOf("es") || !language.indexOf("pe")) {
         locale = "es";
      }
      return locale;
   } catch (e) {
      return locale;
   }
}

function setHtmlText(nodeList = document.querySelectorAll("[data-i18n]")) {
   nodeList.forEach((node) => {
      const { dataset } = node;
      const label = node.getAttribute("label"); // dropw-down 메뉴, input등 처리
      if(label !== null) {
         node.label = i18next.t(dataset.i18n);
      } else {
         node.innerText = i18next.t(dataset.i18n);
      }
   });
}

initI18N();

export default {
   convertMenuJson,
   initI18N,
   setHtmlText
};
